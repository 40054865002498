/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function ItemCard(props) {
  const {
    account,
    amount,
    recurrence = "MONTHLY",
    handleEdit,
    handleDelete,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      width="320px"
      alignItems="flex-start"
      position="relative"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "ItemCard")}
    >
      <Flex
        gap="0"
        direction="column"
        shrink="0"
        height="68px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Account Info")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="20px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={account?.accountName}
          {...getOverrideProps(overrides, "T-Shirt")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${"Starts: "}${account?.dueDate}`}
          {...getOverrideProps(overrides, "Classic Long Sleeve29766849")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={recurrence}
          {...getOverrideProps(overrides, "Classic Long Sleeve34482689")}
        ></Text>
      </Flex>
      <Flex
        gap="12px"
        direction="column"
        width="118px"
        alignItems="stretch"
        grow="1"
        basis="118px"
        height="65px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Right Column")}
      >
        <Flex
          gap="8px"
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ActionFrame")}
        >
          <Button
            display="flex"
            gap="0"
            width="fit-content"
            justifyContent="flex-end"
            alignItems="center"
            shrink="0"
            height="33px"
            position="relative"
            size="small"
            isDisabled={false}
            variation="primary"
            children="Edit"
            {...getOverrideProps(overrides, "EditButton")}
          ></Button>
          <Button
            display="flex"
            gap="12px"
            width="fit-content"
            justifyContent="flex-end"
            alignItems="center"
            shrink="0"
            height="33px"
            position="relative"
            size="small"
            isDisabled={false}
            variation="primary"
            children="X"
            onClick={handleDelete}
            {...getOverrideProps(overrides, "DeleteButton")}
          ></Button>
        </Flex>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="20px"
          textAlign="right"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={amount}
          {...getOverrideProps(overrides, "$99")}
        ></Text>
      </Flex>
    </Flex>
  );
}
