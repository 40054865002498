import logo from './logo.svg';
import './App.css';

import { Amplify, DataStore } from 'aws-amplify';
import { Account } from "./models";

import { useCallback, useEffect } from 'react';
import { withAuthenticator, Tabs, TabItem, Link } from '@aws-amplify/ui-react';
import { ItemCardCollection, ItemCardWithButtonsCollection } from './ui-components';
import FormAccountCreation from './my-components/FormAccountCreation';
import { Routes, Route, Link as ReactRouterLink, useNavigate } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import { useTransition } from 'react';
Amplify.configure(awsExports);


function App({ isPassedToWithAuthenticator, signOut, user }) {
  if (!isPassedToWithAuthenticator) {
    //throw new Error(`isPassedToWithAuthenticator was not provided`);
  }

  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap"
        rel="stylesheet"
      />
      <>
        <h1>Hello {user.attributes.given_name}</h1>
        <button onClick={signOut}>Sign out</button>
      </>
      <Routes>
        <Route path="/" element={<Bills />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/accounts/create" element={<CreateAccount />} />
      </Routes>
    </div>
  );
}

function CreateAccount() {
  return (
    <div>
      <ReactRouterLink to="/accounts" component={Link}>Cancel</ReactRouterLink>
      <FormAccountCreation overrides={{
        "width": "100%",
        "maxWidth": "520px",
        "minWidth": "320px",
        "margin": "auto"
      }} />
    </div>
  );
}

function Bills() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/accounts', { replace: true }), [navigate]);

  useEffect(() => {
    async function getBills() {
      const accounts = await DataStore.query(Account);
      const foo = accounts.map((item) => ({
        ...item,
        dueDate: 'crap',
      }));
      console.log(foo);
    }
    getBills();
  }, [])

  return (
    <Tabs defaultIndex={0}>
      <TabItem
        title="Bills">
      </TabItem>

      <TabItem
        onClick={handleOnClick}
        title="Accounts">
      </TabItem>
    </Tabs>
  );
}

function Accounts() {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/', { replace: true }), [navigate]);

  return (
    <Tabs defaultIndex={1}>
      <TabItem
        onClick={handleOnClick}
        title="Bills">
      </TabItem>

      <TabItem title="Accounts">
        <ReactRouterLink to="/accounts/create" component={Link}>Add Account</ReactRouterLink>
        <ItemCardCollection overrideItems={({ item, index }) => ({
          width: "100%",
          maxWidth: "520px",
          minWidth: "320px",
          margin: "auto",
          backgroundColor: index % 2 === 0 ? 'white' : 'lightgray',
          amount: item.amount ? '$' + item.amount : '',
          recurrence: (item.recurrence === "EVERY2MONTHS" ? "EVERY TWO MONTHS" : item.recurrence === "EVERYMONTH" ? "MONTHLY" : item.recurrence),
          handleDelete: () => {
            if (global.confirm("Are you sure?")) {
              DataStore.delete(Account, item.id)
            }
          }
        })} />
        {/* <ItemCardWithButtonsCollection overrideItems={({ item, index }) => ({
          width: "100%",
          maxWidth: "520px",
          minWidth: "320px",
          margin: "auto",
          itemBackgroundColor: index % 2 === 0 ? 'white' : 'lightgray',
          amount: item.amount ? '$' + item.amount : '',
          recurrence: (item.recurrence === "EVERY2MONTHS" ? "EVERY TWO MONTHS" : item.recurrence === "EVERYMONTH" ? "MONTHLY" : item.recurrence)
        })} /> */}

      </TabItem>
    </Tabs>
  );
}

export default withAuthenticator(App);

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}