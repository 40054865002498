import React, { useState } from "react";
import { TextField, SelectField } from "@aws-amplify/ui-react";
import DatePickerField from './DatePickerField.tsx';
import { DataStore } from '@aws-amplify/datastore';
import { Account, PaidBill } from "../models";

export default function FormAccountCreation(props) {
  const [name, setName] = useState("");
  const [values, setValues] = useState({
    accountName: '',
    accountId: '',
    dueDate: '',
    recurrence: 'EVERYMONTH'
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    alert(`Submitting Name ${name}`);

    var valuesToSubmit = {...values};

    valuesToSubmit.dueDate = new Date(values.dueDate);
    console.log(valuesToSubmit.dueDate);
    valuesToSubmit.dueDate = valuesToSubmit.dueDate.getUTCFullYear() + "-" + String(1 + valuesToSubmit.dueDate.getUTCMonth()).padStart(2, '0') + "-" + String(valuesToSubmit.dueDate.getUTCDate()).padStart(2, '0');

    console.log(valuesToSubmit);
    DataStore.save(new Account({...valuesToSubmit}));
  };

  const handleChange = e => {
    setValues(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value
    }));
  }

  return (
    <div>
      <br />
      <form onSubmit={handleSubmit}>
        <TextField
          label="Account Name"
          name="accountName"
          errorMessage="There is an error"
          value={values.accountName}
          onChange={handleChange}
        />
        <br />
        <TextField
          label="Account Id"
          name="accountId"
          errorMessage="There is an error"
          value={values.accountId}
          onChange={handleChange}      
        />
        <br />
        <DatePickerField
          label="Due Date"
          name="dueDate"
          value={values.dueDate}
          onChange={handleChange} 
        />
        <br />
        <SelectField
          label="Recurrence"
          name="recurrence"
          value={values.recurrence}
          onChange={handleChange}
        >
          <option value="EVERYMONTH">Monthly</option>
          <option value="EVERY2MONTHS">Every two months</option>
          <option value="QUARTERLY">Quarterly</option>
          <option value="YEARLY">Yearly</option>
        </SelectField>
        <br />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
}
