// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const RecurrenceEnum = {
  "EVERYMONTH": "EVERYMONTH",
  "EVERY2_MONTHS": "EVERY2MONTHS",
  "QUARTERLY": "QUARTERLY",
  "YEARLY": "YEARLY"
};

const { Bill, Account } = initSchema(schema);

export {
  Bill,
  Account,
  RecurrenceEnum
};